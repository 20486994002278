import React, { Component } from 'react'

export default class NameWithheld extends Component {
    render() {
        return (
            <React.Fragment>
                <h2>Case Study - [Name Withheld]</h2>
                <p>Even the best-run manufacturing operations can experience growing pains when their sales and operations outrun their IT infrastructure. For Fictionalized Inc. [not the actual name], the key to positioning their organization for future growth lay in working with a software partner to develop an IT application platform that could grow with them.</p>
                <p>The client has requested that their name be withheld from publication at this time.</p>
                <h3>A Common Solution to a Good Problem to Have</h3>
                <p>Fictionalized is a well-known manufacturer of industrial textiles and related products. Its experienced management team, efficient production operations, and strong focus on quality assurance have led to consistently solid financial results.</p>
                <p>While Fictionalized has dedicated IT personnel, its scale doesn’t merit a full-time software development team. And its legacy ERP system, which drives so many of its key operations, can’t be easily modified. So in order to track the quality assurance data generated by its lab technicians and product inspectors, Fictionalized, like thousands of other companies large and small, used Microsoft Access to develop small, self-contained database applications.</p>
                <p>For a number of years, those homegrown applications met the company’s needs. Over time, however, as Fictionalized began to diversify their product line and scale up it operations, the IT team found it harder and harder to keep up. Directly modifying the applications&mdash;now being used by dozens of staff in a high-volume production setting&mdash;was risky at best, and in some cases just not feasible. Instead, Fictionalized duplicated the original applications and tailored the copies to the needs of each product or line of business. This strategy worked for a while, but soon other problems emerged.</p>
                <h3>Downsides, Only Getting Worse With Time</h3>
                <p>Because their data was scattered across a number of different databases, consistent reporting was very difficult; supervisors were spending hours each week collecting and reporting key performance information. Also, the minor variations from one application to the next created confusion and process friction for the operational staff, making onboarding and cross-training harder than they needed to be. And disturbingly, from time to time, a database application was lost or corrupted, leading to production downtime and the loss of valuable information.</p>
                <p>Hardware was another challenge. Since Access is a PC-based application platform, it was necessary to install PCs in the work areas. For many staff, these applications were the only reason they would have to use a PC at the workplace. Sharing a PC with other staff or having to work on a PC far from the work area added to the cost of collecting this quality information.</p>
                <p>Also, since the various applications couldn’t really “talk” to each other, the company had to rely on paper-based processes much more than it would have liked. A prime example is the “MRB” process, by which Fictionalized’s senior engineers and management triage and adjudicate any defects that may arise in the manufacturing process. It’s critical that these defects be reviewed and dealt with quickly and correctly. With Access, it was necessary for each copy of the application to have its version of the MRB report, which had to be similar in format to all the other databases. Using these reports, the team met twice daily, recording the outcome of their discussions on the paper forms, and walking those forms to the factory floor or conveying the results via telephone.</p>
                <h3>Stopgaps, Then a More Comprehensive Solution</h3>
                <p>Fictionalized recognized how important these applications were to their ongoing operations, and were well aware of the challenges they faced. But they also wanted to be prudent in how they used their IT budget, and to minimize disruptions and lost production. So they invested significantly in third-party consulting and development services with the goal of improving and streamlining their functionality and reliability of their existing Access applications. These engagements were moderately successful for a time, but Fictionalized ultimately came to realize that they were only buying time. It was time for more decisive action.</p>
                <p>But how could they position themselves for future growth without wrecking current operations? Two important decisions would be critical to their success: what exactly should they build, and who should they partner with to get it built?</p>
                <p>They found a reliable partner in ClearEye Consulting, a small custom software and technology consulting firm based in Chattanooga, Tennessee, that focuses on providing tailored business technology solutions to small and medium-sized businesses.</p>
                <p>After carefully analyzing their current situation and future needs and goals, ClearEye recommended that Fictionalized gradually migrate their existing QA processes to a new, custom application built with Microsoft ASP.NET Core and Microsoft SQL Server.</p>
                <p>SQL Server was an obvious choice for this project. Fictionalized already had SQL Server infrastructure available (they had migrated the “back ends” of a handful of their Access databases to SQL Server) and were experienced with its use.</p>
                <p>The decision to use ASP.NET was also based on existing infrastructure, namely, a Microsoft Windows Server 2012 instance that could be used to host the application under Microsoft IIS. Using ASP.NET Core helped to better position Fictionalized for future growth, including the prospect of moving some workloads to inexpensive Linux-based hardware on the cloud.</p>
                <h3>Managing Project Risk</h3>
                <p>Business leaders are wise to be cautious when it comes to managing large IT projects. Even with the best tools and practices, it’s still surprisingly easy to fail. ClearEye’s approach to helping its clients manage this risk is to follow an “iterative” process modeled after Agile development practices. Rather than plan out multi-month phases where they construct the entire product in relative isolation, ClearEye developers work in short cycles, preferably two weeks, during which they add a relatively small chunk of functionality. The client reviews that small chunk and provides feedback. That feedback, plus any changes in the business environment or corporate priorities, sets the direction for the next short cycle of work. The goal is to put the software into actual production use as soon as possible and to build on it with each cycle. Not all cycles will result in an update being rolled out to the users, but ideally many or most will. This allows changes to be absorbed gradually, and any negative impacts to be dealt with immediately.</p>
                <p>Fictionalized agreed to this approach, and ClearEye got to work. In short order, a small pilot group of inspectors were using the new system with good results. Some time later, Fictionalized’s lab technicians began using the new system for a pilot group of product types. Over time, adjustments and enhancements were made, additional users and product types were incorporated, and, eventually, essentially the entire staff were using the new system, logging tens of thousands of test observations and hundreds of thousands of yards of inspections each week.</p>
                <h3>Looking at the Results</h3>
                <p>Was the investment worthwhile?</p>
                <p>Before, any change in product configuration called for advanced Microsoft Access skills and nerves of steel. Now, supervisors can configure the system using a custom-built UI and be confident that their changes will not harm operations. Fictionalized was even able to add an entire new product category without breaking a sweat (or the application).</p>
                <p>Before, any across-the-board changes would take days, if not weeks, to complete. Recently, Fictionalized needed to perform some significant rebranding of the application. Because of the centralized architecture and industry best-practices built into the application, ClearEye was able to complete this work as a minor enhancement at a minimal cost.</p>
                <p>Before, the application only worked on Windows PCs. Now it can be used from any device with a web browser, including tablets and even phones.</p>
                <p>Before, supervisors would spend hours each week calculating operational metrics, often from printed reports. Now those reports are available anytime with zero effort from anyone.</p>
                <p>Before, measuring employee productivity was complex and imprecise. Now, all key productivity events are in a centralized system and easily extracted with built-in tools.</p>
                <p>Before, there wasn’t really a good way to identify variance and waste in the process. Now, it’s all right there in the database.</p>
                <p>Before, when the lab noted a manufacturing anomaly, the process for reporting that anomaly to the floor was cumbersome. Now, the floor is able to see and react to lab results instantaneously.</p>
                <p>Before, highly-paid staff were meeting twice a day to decide what to do about manufacturing defects. Now, they can review and deal with those defects anytime, from anywhere, with a multi-stage approval process and complete auditing to ensure process integrity and appropriate governance.</p>
                <p>Before, while Fictionalized always acted with integrity, there were no safeguards to prevent employees from tampering with the results. Now, the results reported to the clients come straight from the system, with data protection controls to ensure that the results are accurate and trustworthy.</p>
                <p>Before, the databases would sometimes get corrupted or lost, or just stop working. Now they don’t.</p>
                <p>Remarkably, Fictionalized was able to achieve all of these benefits with extremely limited downtime and no observable loss of productivity. And at least anecdotally (the old applications didn’t provide a sufficient baseline for empirical comparison), employees seem to be really enjoying the new system and find it much easier to learn and use.</p>
                <h3>Looking Forward</h3>
                <p>Now that Fictionalized has completed this migration toward a more forward-looking IT platform, it finds itself well-positioned for future growth. Its legacy ERP application is still providing strong business value at the center of its operations, but now there’s a clear path by which they can safely migrate parts of that functionality like they did with their quality applications. And because their new application is built on web technologies, a whole new world of integration possibilities is open to them, both with internal systems and B2B.</p>
                <p>Fictionalized Inc. has always been committed to delivering quality products to their customers. Their thoughtful investments in custom quality software have made it easier and less expensive to ensure quality results for a long time to come.</p>
            </React.Fragment>
        )
    }
}